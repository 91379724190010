import React from "react";
import styled from "styled-components";
import Button from "../shared/buttons/Button.jsx";
import mainImage from "../../assets/imgs/main/main.webp";
import { DEVICES } from "../../constants/layout.js";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import BoxedContainer from "../shared/container/BoxedContainer.jsx";
import { ORDER_ROUTE } from "../../constants/routes.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";

const LeftPart = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 72px;
  @media ${DEVICES.tablet} {
    width: 100%;
    margin-right: 0px;
    justify-content: center;
    align-items: flex-start;
    margin-top: 10%;
  }
`;

const RightPart = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  @media ${DEVICES.tablet} {
    width: 100%;
  }
`;
const Content = styled.div`
  width: 100%;
  max-width: 400px; /* Max width for the left part */
`;

const ImageContainerSecondary = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${DEVICES.tablet} {
    padding-right: 12px;
    padding-left: 12px;
  }
`;

const ImageMain = styled.img`
  height: 100%;
  width: 100%;
  margin: 10px;
  max-width: 528px;
  min-width: 80px;
  border-radius: 8px;
`;

const Header = styled.h1`
  font-size: 62px;
  margin-bottom: 20px;
  font-weight: 600;
  line-height: 1.08;

  @media ${DEVICES.laptopL} {
    font-size: 48px;
  }
  @media ${DEVICES.laptopM} {
    font-size: 40px;
  }
  @media ${DEVICES.tablet} {
    font-size: 34px;
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  margin-bottom: 20px;
`;

const SmallText = styled.p`
  margin-top: 14px;
  font-size: 14px;
`;

const WelcomeSection = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const onOrderCLick = () => {
    history.push(`${ORDER_ROUTE}/4`);
  };

  const isTabletOrMobile = useMediaQuery({ query: DEVICES.tablet });
  const isLaptop = useMediaQuery({ query: DEVICES.laptopM });

  return (
    <BoxedContainer height="89vh" marginBottom="24px">
      <LeftPart>
        <ImageContainerSecondary>
          <ImageMain src={mainImage} alt="Main image" />
        </ImageContainerSecondary>
      </LeftPart>
      <RightPart>
        <Content>
          <Header>{t("welcomeSection.title")}</Header>
          {!isTabletOrMobile && (
            <>
              <Description>{t("welcomeSection.subtitle")}</Description>
              <Button
                variant="outline-secondary"
                width={isLaptop ? "320px" : "360px"}
                onClick={() => onOrderCLick()}
              >
                {t("welcomeSection.orderButton")}
              </Button>
              <SmallText>{t("welcomeSection.smallText")}</SmallText>
            </>
          )}
        </Content>
      </RightPart>
    </BoxedContainer>
  );
};

export default WelcomeSection;
