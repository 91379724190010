import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { LIGHT_GRADIENT_COLOR } from "../../constants/colors";
import { DEVICES, SIDE_PADDING } from "../../constants/layout";
import { useAuth } from "../../contexts/AuthContext";
import { signOut } from "../../redux/actions";
import { Icon } from "../shared/icons/Icon";
import AddressBook from "./AddressBook";
import MyProfile from "./MyProfile";
import OrderHistory from "./OrderHistory";
import Settings from "./Settings";
import { useTranslation } from "react-i18next";
import usePermissions from "../../hooks/usePermissions";

const Container = styled.div`
  @media ${DEVICES.tablet} {
    flex-direction: column;
    padding: 4px 8px;
  }
  /* background: ${LIGHT_GRADIENT_COLOR}; */
  /* min-height: 90vh; */
  max-height: fit-content;
  height: 100%;
  width: 100%;
  display: flex;
  padding: 40px ${SIDE_PADDING};
`;

const Navigation = styled.nav`
  @media ${DEVICES.tablet} {
    display: flex;
    justify-content: space-around;
    height: 60px;
    width: 100%;
  }
  height: 200px;
  width: 260px;
`;

const IconContainer = styled.div`
  @media ${DEVICES.mobileL} {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media ${DEVICES.tablet} {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  width: 32px;
`;

const Span = styled.span`
  @media ${DEVICES.tablet} {
    white-space: nowrap;
    font-size: 12px;
  }
  @media ${DEVICES.mobileL} {
    font-size: 10px;
    /* display: none; */
  }
  user-select: none;
`;

const List = styled.ul`
  @media ${DEVICES.tablet} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const ListItem = styled.ul`
  @media ${DEVICES.mobileL} {
    flex-direction: column;
    padding: 4px 10px !important;
    transition: transform 0.1s;
    &:hover {
      transform: scale(2);
    }
  }
  @media ${DEVICES.tablet} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    padding: 4px 20px;
  }
  margin-left: 0px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-bottom: 20px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const Account = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(""); // eslint-disable-line
  const { logout } = useAuth();
  const { isAdmin } = usePermissions();
  const history = useHistory();
  const { profileRoute } = useParams();
  // let displayName = currentUser.displayName ? currentUser.displayName : "Guest";
  // let displayEmail = currentUser.email ? currentUser.email : "Not provided";

  // eslint-disable-next-line
  async function handleLogout() {
    setError("");

    try {
      await logout();
      dispatch(signOut());
      history.push("/signin");
    } catch {
      setError("Failed to log out");
    }
  }
  const renderPaper = (route) => {
    switch (route) {
      case "order-history": {
        return <OrderHistory />;
      }
      case "address-book": {
        return <AddressBook />;
      }
      case "account-settings": {
        return <Settings />;
      }
      default: {
        return <MyProfile />;
      }
    }
  };

  return (
    <Container>
      <Navigation>
        <List>
          <ListItem onClick={() => history.push("/profile")}>
            <IconContainer>
              <Icon cursor="pointer" iconName="fa fa-user-circle" />
            </IconContainer>
            <Span>{t("profile.myProfile")}</Span>
          </ListItem>
          <ListItem onClick={() => history.push("/profile/order-history")}>
            <IconContainer>
              <Icon cursor="pointer" iconName="fa fa-cart-arrow-down" />
            </IconContainer>
            <Span>{t("profile.orderHistory")}</Span>
          </ListItem>
          <ListItem onClick={() => history.push("/profile/address-book")}>
            <IconContainer>
              <Icon cursor="pointer" iconName="fa fa-book" />
            </IconContainer>
            <Span>{t("profile.myAddresses")}</Span>
          </ListItem>
          {!!isAdmin && (
            <ListItem onClick={() => history.push("/dashboard")}>
              <IconContainer>
                <Icon cursor="pointer" iconName="fa fa-database" />
              </IconContainer>
              <Span>Dashboard</Span>
            </ListItem>
          )}
          {/* <ListItem onClick={() => history.push("/profile/account-settings")}>
            <Icon cursor="pointer" width="32px" iconName="fa fa-cog" />
            <Span>Account Settings</Span>
          </ListItem> */}
          <ListItem onClick={() => logout()}>
            <IconContainer>
              <Icon cursor="pointer" iconName="fa fa-sign-out" />
            </IconContainer>

            <Span>{t("authentication.signOut")}</Span>
          </ListItem>
        </List>
      </Navigation>
      {renderPaper(profileRoute)}
    </Container>
  );
};

export default Account;
