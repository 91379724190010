import React, { useEffect, useState, useMemo } from "react";
import CheckoutTotal from "./CheckoutTotal";
import ProgressModal from "./ProgressModal";
import { useDispatch, useSelector } from "react-redux";
import { db, storageRef } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import { clearCart } from "../../redux/actions";
import ErrorModal from "../Errors/errorModal";
import axios from "axios";
import { toast } from "react-toastify";
import { useAuth } from "../../contexts/AuthContext";
import { Formik } from "formik";
import { Col, Form } from "react-bootstrap";
import AddEditAddress from "../Account/AddEditAddress";
// import { addAddressValidation } from "../shared/validation/useValidation";
import AddressBook from "../Account/AddressBook";
import { useTranslation } from "react-i18next";
import { useAddAddressValidation } from "../shared/validation/useValidation";

const Checkout = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const addAddressValidation = useAddAddressValidation();
  const subtotal = useSelector((state) => state.subtotal);
  const { currentUser, loginAsGuest } = useAuth();
  const cart = useSelector((state) => state.cart);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let [progress, setProgress] = useState(0);
  let [cartImagesCount, setCartImagesCount] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [checkoutAddress, setCheckoutAddress] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [allAddresses, setAllAdresses] = useState([]);

  const cartInfo = useMemo(
    () => (cart || []).map((d) => `${d.quantity} - ${d.size}`).join(", "),
    [cart]
  );

  const handleCloseError = () => setShowErrorModal(false);
  const handleShowError = () => setShowErrorModal(true);

  useEffect(
    () => {
      if (currentUser?.email) {
        let newUserDetails = {};

        (async () => {
          setLoading(true);
          const userDb = db.collection("users").doc(currentUser.uid);
          const userDetails = await userDb.get();
          if (!userDetails.exists) {
            toast.error(t("error.somethingWentWrong"));
          } else {
            newUserDetails = userDetails.data();
          }
          setUserDetails(newUserDetails);
          setAllAdresses(newUserDetails?.addresses || []);
          setLoading(false);
          return userDetails;
        })();
      }

      return userDetails;
    },
    // eslint-disable-next-line
    [currentUser?.uid]
  );

  useEffect(() => {
    let sum = 0;
    function count() {
      cart.forEach((el) => {
        sum += el.images.length;
      });
      setCartImagesCount(sum);
    }
    count();
  }, [cart]);

  const orderDone = () => {
    dispatch(clearCart());
  };

  const handleSubmitClick = async (values) => {
    let guest;
    if (currentUser === null) {
      guest = await loginAsGuest();
    }

    if (guest || currentUser) {
      if (cart.length > 0) {
        const orderData = !!checkoutAddress
          ? {
              user_uid: guest?.user?.uid || currentUser?.uid,
              user_name: checkoutAddress.firstName,
              user_lastname: checkoutAddress.lastName,
              user_phone: checkoutAddress.phone,
              user_address: checkoutAddress.address,
              user_zip: checkoutAddress.zip,
              user_city: checkoutAddress.city,
              user_state: checkoutAddress.state,
              saveAddress: false,
              user_comment: checkoutAddress.comment || values?.comment || "",
              order_number: `${uuidv4().slice(0, 3)}${Date.now()}`,
              order_price: subtotal,
              order_info: cartInfo,
              order_date: new Date().toLocaleString(),
              cart_items: cart.length,
            }
          : {
              user_uid: guest?.user?.uid || currentUser?.uid,
              user_name: values.firstName,
              user_lastname: values.lastName,
              user_phone: values.phone,
              user_address: values.address,
              user_zip: values.zip,
              user_city: values.city,
              user_state: values.state,
              saveAddress: values.isDefault,
              user_comment: values.comment,
              order_number: `${uuidv4().slice(0, 3)}${Date.now()}`,
              order_price: subtotal,
              order_info: cartInfo,
              order_date: new Date().toLocaleString(),
              cart_items: cart.length,
            };

        let date = new Date();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        let day = date.getDate();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let newDate = `${year}-${month}-${day}__${hours}-${minutes}`;

        setProgress(0);

        let folderId = uuidv4();
        let sum = 0;

        try {
          cart.forEach((cartItem) => {
            cartItem.images.forEach(async (file) => {
              let imgId = Math.floor(Math.random() * 100000);
              let shortSize = cartItem.size.split(" ").join("");
              await storageRef
                .child(
                  `orders/${newDate}-${folderId}/${file.count}_aspect_${shortSize}__${imgId}_${file.img.name}`
                )
                .put(file.img);

              sum += 1;
              let progress = Math.floor((sum / cartImagesCount) * 100);
              setProgress(progress);

              if (sum === cartImagesCount) {
                try {
                  let browserUrl =
                    "https://console.firebase.google.com/u/0/project/yourphoto-app/storage/yourphoto-app.appspot.com/files/~2Forders~";
                  let url = await storageRef.child(
                    `order/${newDate}-${folderId}`
                  ).name;
                  let downloadUrl = `${browserUrl}2F${url}`;
                  await db
                    .collection("orders")
                    .doc(`${newDate}-${folderId}`)
                    .set({
                      ...orderData,
                      order_date: new Date(),
                      browserPath: downloadUrl,
                      storageName: url,
                    });
                  setModalShow(true);
                  orderDone();

                  const webhookUrl =
                    process.env.REACT_APP_SLACK_APP_WEBHOOK_URL;
                  const info = `
:date: *Date*: ${newDate}          
:raising_hand: *Name*: ${orderData.user_name} ${orderData.user_lastname}
:phone: *Phone*: ${orderData.user_phone}
:cityscape: *State*: ${orderData.user_state} ${orderData.user_zip}
:house: *Address*: ${orderData.user_city}, ${orderData.user_address}
:moneybag: *Price*: ${orderData.order_price} AMD (cash)
:memo: *Comment*: ${orderData.user_comment}
:package: *Order Info*: ${orderData.order_info}
:card_file_box: gsutil -m cp -r gs://yourphoto-app.appspot.com/orders/${url} ./Firebase
${downloadUrl}`;
                  const payload = `{"text":"${info}"}`;
                  const data = payload;
                  try {
                    await axios.post(webhookUrl, data);
                  } catch {
                    toast.error(
                      `ERROR: ${folderId}, ${t("error.orderError")}`,
                      {
                        position: "top-right",
                        autoClose: 30000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      }
                    );
                  }
                } catch {
                  setErrorMessage(t("error.noInternetConnection"));
                  handleShowError();
                }
              }
            });
          });
        } catch (error) {
          setErrorMessage(t("error.noInternetConnection"));
          handleShowError();
        }
        setModalShow(true);
      } else {
        setErrorMessage(t("error.emptyCart"));
        handleShowError();
      }
    } else {
      setErrorMessage(t("error.failedToCreateAccount"));
      handleShowError();
    }
    if (currentUser && values.isDefault && !allAddresses.length) {
      try {
        db.collection("users")
          .doc(currentUser.uid)
          .set(
            {
              addresses: [{ id: uuidv4(), ...values }],
            },
            { merge: true }
          );
      } catch {
        toast.error(t("error.addingAddress"));
      }
    }
  };

  return (
    <Formik
      validationSchema={!checkoutAddress ? addAddressValidation : undefined}
      onSubmit={(values) => handleSubmitClick(values)}
      initialValues={{
        firstName: "",
        lastName: "",
        address: "",
        phone: "",
        city: t("states.yerevan"),
        state: t("states.yerevan"),
        zip: "",
        comment: "",
        isDefault: true,
      }}
    >
      {({ handleSubmit, handleChange, values, touched, errors }) => (
        <Form>
          <div className="checkout-container">
            <div className="checkout-info">
              <div className="checkout-payment">
                <fieldset>
                  <Form.Group as={Col} style={{ padding: "0px" }}>
                    <Form.Label as="legend" column sm={10}>
                      {t("printService.paymentMethod")}
                    </Form.Label>
                    <Col sm={10}>
                      <Form.Check
                        type="radio"
                        defaultChecked="true"
                        label={t("printService.cashPayment")}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios1"
                      />
                      <Form.Check
                        type="radio"
                        disabled={true}
                        label={t("printService.cardPayment")}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios2"
                      />
                      <Form.Check
                        type="radio"
                        disabled={true}
                        label={t("printService.idramPayment")}
                        name="formHorizontalRadios"
                        id="formHorizontalRadios3"
                      />
                    </Col>
                  </Form.Group>
                </fieldset>
              </div>
              <div className="checkout-shipping">
                {!loading && currentUser?.email && allAddresses.length ? (
                  <>
                    <AddressBook
                      checkoutMode
                      checkoutAddress={checkoutAddress}
                      setCheckoutAddress={setCheckoutAddress}
                    />
                    <Form.Group className="mt-4" controlId="formGridCommentBox">
                      <Form.Control
                        name="comment"
                        placeholder={t("address.enterComment")}
                        value={values.comment}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </>
                ) : (
                  <AddEditAddress
                    hideCheckbox
                    showCommentForm
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                  />
                )}
              </div>
            </div>

            <div className="checkout-total">
              <ProgressModal
                signedUser={currentUser?.email}
                progress={progress}
                show={modalShow}
                onClose={() => setModalShow(false)}
              />
              <ErrorModal
                handleCloseError={handleCloseError}
                handleShowError={handleShowError}
                errorMessage={errorMessage}
                show={showErrorModal}
              />
              <CheckoutTotal
                cart={props.cart}
                subtotal={props.subtotal}
                itemsSum={props.itemsSum}
                submitOrder={() => handleSubmit(touched)}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Checkout;
