import React from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import Button from "../shared/buttons/Button.jsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ProgressModal(props) {
  const { progress, onClose, signedUser, show } = props;
  const { t } = useTranslation();

  if (progress === 100) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <h6>{t("order.uploadedSuccessfully")}</h6>
          <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ color: "green", marginLeft: "3px" }}
          ></i>
        </Modal.Header>
        <Modal.Body className="d-flex justify-content-center flex-column">
          <h4 className="order-completed-header">{t("order.thankYou")}</h4>
          <h4 className="order-completed-text">{t("order.orderPlaced")}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Link to={signedUser ? "/profile/order-history" : "/"}>
            <Button variant="primary">{t("actions.done")}</Button>
          </Link>
        </Modal.Footer>
      </Modal>
    );
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onBackdropClick={(e) => {
        e.preventDefault();
        show(true);
      }}
    >
      <Modal.Header
        closeButton={false}
        onHide={(e) => {
          e.preventDefault();
          show(true);
        }}
      >
        <h6>{t("order.waitForUploadText")}</h6>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center flex-column">
        <ProgressBar
          style={{ width: "100%", marginTop: "20px" }}
          now={progress}
        />
        <h6 style={{ color: "grey", alignSelf: "center", margin: "10px" }}>
          {t("order.uploading")} {progress}%
        </h6>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled onClick={onClose}>
          {t("actions.done")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ProgressModal;
