export const hy = {
  welcomeSection: {
    title: "Ստեղծիր գեղեցիկ հիշողություններ",
    subtitle:
      "հավերժացրու կյանքի ամենաթանկ պահերը բարձրորակ լուսանկարների տպագրությամբ",
    orderButton: "Պատվիրել հիմա",
    smallText: "Երևանում ստացիր պատվերդ 1-3 օրվա ընթացքում",
  },
  photographySection: {
    title: "Մենք լուսանկարում ենք պահը, դարձնելով այն գունեղ հիշողություն",
    subtitle: "",
    services: "Ծառայություններ",
    smallEvent: "Փոքր Առիթներ",
    portraitPhotography: "Դիմանկարներ",
    familyPhotography: "Ընտանեկան Լուսանկարներ",
    capturedMoments: "Գեղեցիկ Պահեր",
    pricing: "Գնացուցակ",
    basicPackage: "Հիմնական Փաթեթ",
    basicPackagePrice: "սկսած 15.000դր.",
    basicPackageDescription:
      "Հիանալի տարբերակ անհատական լուսանկարահանման համար",
    eventsPackage: "Փոքր Առիթների Փաթեթ",
    eventsPackagePrice: "սկսած 49.000դր.",
    eventsPackageDescription: "հիանալի տարբերակ փոքր խնջույքների համար",
    contactUs: "Կապ Հաստատել",
    name: "Անուն",
    phone: "Հեռախոսահամար",
    email: "Էլ. Հասցե",
    message: "Գրել նամակ",
    send: "Ուղարկել նամակ",
    successMessage: "Նամակը հաջողությամբ ուղարկվեց",
  },
  menu: {
    prints: "Ֆոտո-տպում",
    photography: "Լուսանկարում",
    ideas: "Մտքեր",
    app: "Հավելված",
    howToUse: "Հաճախ տրվող հարցեր",
    delivery: "Առաքում",
    aboutUs: "Մեր մասին",
    contacts: "Հետադարձ Կապ",
  },
  service: {
    exploreFeatures: "Ընտրիր նկարի քո տարբերակը",
  },
  ideas: {
    title: "Մտքեր լուսանկարները ցուցադրելու համար",
    spotlight: "Գեղեցիկ և Նորաոճ",
    spotlightDescription:
      "Այս գեղեցիկ տարբերակը հիանալի կերպով աշխատում է մեր բոլոր լուսանկարների համար, բայց այն ավելի լավ տեսք ունի երբ ընտրում ես ռետրո նկարներ: Քեզ անհրաժեշտ է ընդամենը տպել լուսանկարները պոլարոիդ տարբերակով, վերցնել ռետրո ծրարներ, փոքրիկ ամրակներ և քո լավագույն պահերին մի փոքր ավել ուշադրություն ավելացնելու համար, մի քանի լարային լույսեր:",
    quickAndEasy: "Արագ և հեշտ",
    quickAndEasyDescription:
      "Ժամանակը քիչ է, բայց փնտրում ես նոր տպագրությունները ցուցադրելու շքեղ միջոց: Ահա մի գաղափար. օգտագործիր թղթի սեղմակ՝ եզակի լուսանկարի կրիչ ստեղծելու համար: Պարզապես սեղմակը հարթ կանգնեցրու մակերեսի վրա, փակիր երկու մետաղական թևերը, և դիր լուսանկարը ամրակի մեջ: Ավելի շքեղ տեսք ունենալու համար փորձիր օգտագործել ոսկե կամ վարդագույն ոսկուց սեղմակներ:",
    classic: "Դասական տարբերակ",
    classicDescription:
      "Անկախ նրանից, կընտրես շրջանակով տպագրությունը, թե լուսանկարները շրջանակի մեջ կդնես տանը, սա սիրած լուսանկարները ցուցադրելու ամենահեշտ և դասական եղանակն է: Լուսանկարներն ավելի շատ աչքի ընկնելու համար ընտրիր շրջանակ, որը զիջում է տպագրության գույներին, որ նկարդ իսկապես փայլի:",
  },
  application: {
    reviewInfo: "ԱՎԵԼԻ ՔԱՆ 5 ՀԱԶԱՐ ԱԿՏԻՎ ՀԱՃԱԽՈՐԴ",
    printInfo: "Տպիր նկարներ անմիջապես հեռախոսից կամ համակարգչից",
    cta: "Նայել ուղեցույցը",
  },
  keyFeatures: {
    title: "Ինչպե՞ս օգտվել ծառայությունից",
    choose: "Ընտրիր նկարի քո տարբերակը",
    chooseDescription:
      "Դասական ֆոտոներ յուրաքանչյուր առիթի համար: Դեկորատիվ շրջանակներ հատուկ պահերն ընդգծելու համար։ Կպչուն պիտակներ՝ ամսագրի ձևավորման համար և այլն:",
    personalize: "Ավելացրու քո կարգավորումները",
    personalizeDescription:
      "Լուսանկարները հեշտությամբ վերբեռնիր կայք: Հեռախոսից կայքով վերբեռնումները նույնպես շատ հեշտ են: Անհատականացրու պատվերդ հատուկ գործիքներով: Կատարիր հիանալի նվեր ընկերների և ընտանիքի համար:",
    delivery: "Ստացիր առաքում",
    deliveryDescription:
      "Ակնթարթային պատվերով և արագ առաքմամբ խնայիր ժամանակդ և վայելիր գեղեցիկ հիշողություններդ: Առաքումը հասանելի է նաև Հայաստանի մարզերում։",
  },
  delivery: {
    title: "Առաքում",
    hint: "Վճարիր 600 դրամ առաքման համար եթե պատվերիդ գումարը գերազանցում է 1500 դրամը",
    preparation:
      "Գրանցված պատվերները պատրաստել և առաքելու համար մի քանի օր է պահանջվում: Երևանում պատվերները առաքվում են միջինում 1-3 օրվա ընթացքում, 1500 դրամ կամ ավելի պատվերի դեպքում առաքման արժեքն է 600 դր.։",
    process:
      " Հայաստանի մարզերից կատարվող պատվերների համար մենք օգտվում ենք «Հայփոստի» ծառայությունից՝ մոտակա բաժանմունք կամ տուն առաքելու համար։ Այս դեպքում պատվերի առաքումը կարող է տևել մինչև 2-7 աշխատանքային օր, առաքման արժեքը նույնն է ինչ Երևանում:",
    express:
      "*Ավելի արագ առաքման համար խնդրում ենք նախապես ճշտել աշխատակցի հետ, նախքան պատվերը գրանցելը: Արագ առաքման արժեքը կարող է տարբերվել հիմնական առաքման արժեքից:",
  },
  about: {
    title: "Մեր մասին",
    text: "Գաղափարը իրագործելիս մեր առաջնային նպատակն է եղել վերադարձնել մարդկանց կողմից՝ կյանքի ուրախ ու հիշարժան պահերը միմյանց հետ կիսելու մոռացված սովորույթը: Մեր փոքրիկ բայց նպատակասլաց թիմը հիմնել է ծառայությունը 2016թ.-ին լինելով առաջինը Հայաստանում որ ստեղծեց առցանց հարթակ, լուսանկարների տպագրության համար:",
    mission:
      "Մեր նպատակն է օգնել մարդկանց լուսանկարել, պահել և կիսել կյանքի կարևոր պահերը պրոֆեսիոնալ կամ հեռախոսի լուսանկարների միջոցով: Ծառայութունը վերակենդանացնում է լուսանկարներ տպելու և դրանցով ֆոտոալբոմներ, ֆոտոգրքեր, տան ձևավորումներ կամ որպես նվեր մատուցելու սովորույթը նորագույն տարբերակով: Մենք օգտագործում ենք բարձրորակ ֆոտոթղթեր, որոնք համապատասխանում են Եվրոպական չափանիշներին և գործադրում ենք բոլոր ջանքերը լուսանկարների որակը բարձր պահելու համար:",
    subText:
      "Մենք անում ենք ամեն բան օգնելու ձեզ առավելագույնս օգտագործել կյանքի ամենահիշարժան պահերը: Որպես անհատականացված լուսանկարների առցանց տպման ծառայություն, մենք թույլ ենք տալիս գրանցել պատվեր և ստանալ առաքում առանց տանից դուրս գալու: Մենք օգնում ենք ձեզ կապ հաստատել ձեր ընտանիքի և ընկերների հետ՝ կիսվելով ձեր հիշողություններով և լուսանկարներով նորարարական տարբերակով:",
    shortText:
      "Երևանում ստեղծված մեր կազմակերպությունը ծառայություններ է մատուցում ամբողջ Հայաստանի տարածքում: Մենք տպում ենք ձեր լուսանկարները 2016 թվականից ի վեր: Ուրախ ենք օգնել, որպեսզի յուրաքանչյուր գեղեցիկ հիշողություն հավերժ մնա:",
  },
  contacts: {
    title: "Հետադարձ կապ",
    address: "Հասցե",
    phone: "Հեռախոս",
    facebook: "Ֆեյսբուք",
    instagram: "Ինստագրամ",
    whatsappViber: "Վաթսափ/Վայբր",
    addressText: "Երևան, Պարոնյան 6",
    email: "Էլ. փոստ",
    subtitle: "Տվյալներ",
    workingHours: "Աշխատանքային ժամեր",
    workingHoursText: "Երկուշաբթի - Կիրակի",
  },

  footer: {
    home: "Հիմնական",
    navigation: "Ցանկ",
    paymentMethods: "Վճարման տարբերակներ",
    reviews: "Կարծիքներ",
    liveChat: "Սկսել զրույց",
  },
  printService: {
    uploadTitle: "Վերբեռնիր նկարները սկսելու համար",
    uploadText:
      "Սեղմիր 'Վերբեռնել նկարներ' կոճակը կամ ուղակի վերցրու և տեղադրիր նկարները այստեղ:",
    addPhotos: "Ավելացնել նկարներ",
    cta: "Վերբեռնել նկարներ",
    addToCart: "Ավելացնել զամբյուղ",
    successfullyAddedToCart: "Նշված նկարները ավելացված են զամբյուղում",
    successfullyAddedToCartText:
      "Կարող ես փոփոխել կամ ջնջել նկարները, մինչև պատվերի գրանցումը",
    goToCart: "հաջորդ",
    photos: "Նկար",
    bottomText: "Նկար",
    classicPrints: "Դասական տպագրություն",
    addMorePhotos: "Ավելացնել նոր չափսի նկարներ",
    goToCheckout: "Գնալ վճարման էջ",
    cartItems: "Զամբյուղում",
    subtotal: "Արժեք",
    emptyCart: "Զամբյուղը դատարկ է",
    back: "Վերադառնալ",
    total: "Վճարման գումար",
    enterComment: "Ավելացրու մեկնաբանություն",
    placeOrder: "Գրանցել պատվեր",
    allPhotosDeleted: "Բոլոր նկարները ջնջված են",
    paymentMethod: "Վճարման տարբերակ",
    cashPayment: "Կանխիկ վճարում",
    cardPayment: "Քարտային վճարում",
    idramPayment: "Վճարում իդրամ համակարգով",
  },
  address: {
    title: "Հասցե",
    changeAddress: "Փոխել հասցեն",
    addAddress: "Ավելացնել հասցե",
    firstName: "Անուն",
    lastName: "Ազգանուն",
    phone: "Հեռախոս",
    city: "Քաղաք",
    state: "Մարզ",
    zipCode: "Փոստային կոդ",
    comment: "Մեկնաբանություն",
    saveAddress: "Հիշել հասցեն",
    enterName: "անուն",
    enterLastName: "ազգանուն",
    enterCity: "քաղաք",
    enterState: "մարզ",
    enterZipCode: "հայփոստի ինդեքս",
    enterComment: "ավելացրու մեկնաբանություն",
    enterPhone: "077 XX XX XX",
    addressExample: "Պարոնյան 6, բնակարան 234, մուտք 7",
    enterAddress: "հասցե",
    addressSaved: "Հասցեն հիշված է",
    addNewAddress: "Ավելացնել նոր հասցե",
    chooseFromAddresses: "Ընտրել պահված հասցեներից",
    deleteAddress: "Ջնջել հասցեն",
    deleteAddressText:
      "Համոզվա՞ծ ես, որ ցանկանում ես անվերադարձ ջնջել այս հասցեն քո ցանկից",
    setAsDefault: "Դարձնել հիմնական հասցե",
  },
  states: {
    yerevan: "Երևան",
    ararat: "Արարատ",
    aragatsotn: "Արագածոտն",
    armavir: "Արմավիր",
    gegharkunik: "Գեղարքունիք",
    kotayk: "Կոտայք",
    lori: "Լոռի",
    shirak: "Շիրակ",
    syunik: "Սյունիք",
    tavush: "Տավուշ",
    vayotsDzor: "Վայոց Ձոր",
  },
  countries: {
    armenia: "Հայաստան",
  },
  authentication: {
    signIn: "Մուտք գործել",
    signUp: "Գրանցվել",
    signUpTitle: "Ստեղծել նոր հաշիվ",
    signOut: "Դուրս գալ",
    forgotPassword: "Մոռացե՞լ ես գաղտնաբառը",
    login: "Մուտք",
    continueAsGuest: "Շարունակել որպես հյուր",
    dontHaveAccount: "Դեռ գրանցվա՞ծ չես",
    email: "Էլ. հասցե",
    phone: "Հեռախոս",
    password: "Գաղտնաբառ",
    enterEmail: "էլ. հասցե",
    confirmPassword: "Գաղտնաբառի հաստատում",
    enterPassword: "գաղտնաբառ",
    enterConfirmPassword: "կրկնել գաղտնաբառը",
    enterPhoneNumber: "077 XX XX XX",
    resetYourPassword: "Վերականգնիր գաղտնաբառը",
    resetPassword: "Վերականգնել",
    backTo: "Վերադարձ դեպի",
    firstName: "Անուն",
    lastName: "Ազգանուն",
    enterName: "անուն",
    enterLastName: "ազգանուն",
    alreadyHaveAccount: "Արդեն գրանցվա՞ծ ես",
  },
  profile: {
    user: "Անուն",
    myProfile: "Իմ էջը",
    myAddresses: "Իմ հասցեները",
    orderHistory: "Պատվերներ",
    recentOrders: "Վերջին Պատվերներ",
    noRecentOrders: "Դեռ չունես գրանցած պատվեր",
    orderInfo: "Պատվերի տվյալներ",
    orderDate: "Պատվերի ամսաթիվ",
    orderNumber: "Պատվերի համար",
    cityProvince: "Քաղաք/Մարզ",
    deliveryAddress: "Առաքման հասցե",
    price: "Պատվերի արժեք",
    orderPlaced: "Գրանցված պատվեր",
    personalInformation: "Անձնական տվյալներ",
    userName: "Մուտքանուն",
    birthday: "Ծննդյան ամսաթիվ",
    loginInformation: "Մուտքանվան Տվյալներ",
    userId: "Անձնական համար",
    emailAddress: "Էլ. հասցե",
    noAddressText: "Մուտք գործիր էլ.փոստով, հասցեներից օգտվելու համար",
  },
  actions: {
    delete: "Ջնջել",
    edit: "Խմբագրել",
    update: "Թարմացնել",
    confirm: "Հաստատել",
    cancel: "Չեղարկել",
    save: "Հիշել",
    setAsDefault: "Դարձնել հիմնական",
    done: "Ընդունել",
    close: "Փակել",
    pause: "Դադար",
    hide: "Թաքցնել",
  },
  order: {
    thankYou: "Շնորհակալությու՜ն",
    orderPlaced: "Պատվերն ընդունված է",
    uploadedSuccessfully: "Նկարները ապահով հասել են մեզ",
    waitForUploadText:
      "Խնդրում ենք սպասել, քանի դեռ նկարները վերբեռնվում են...",
    uploading: "վերբեռնում",
  },
  error: {
    somethingWentWrong: "Օօօօ... համակարգում խնդիր է առաջացել",
    somethingWentWrongText:
      "Խնդրում ենք կրկին փորձել գործողությունը կամ կապ հաստատել մեր հետ",
    orderError:
      "Կարծես թե որոշակի տեխնիկական խնդիրներ են առաջացել կայքում, պատվերի գրանցումը ստուգելու համար խնդրում ենք նկարել էկրանը և զանգահարել 077540454 հեռախոսահամարին",
    noInternetConnection: "Ինտերնետ կապի խափանում",
    failedToCreateAccount: "Չհաջողվեց ստեղծել հաշիվ, ստուգեք տվյալները",
    emptyCart: "Զամբյուղը դատարկ է, ավելցրեք նկարներ և փորձեք կրկին",
    addingAddress: "Չհաչողվեց ավելացնել հասցեն",
  },
  validation: {
    nameMinChar: "Անունը պետք է ունենա առնվազն 2 նիշ",
    nameMaxChar: "Անունը պետք է ունենա առավելագույնը 30 նիշ",
    nameRequired: "Անունը պարտադիր է",
    phoneMinChar: "Հեռախոսահամարը պետք է ունենա առնվազն 9 նիշ",
    invalidPhone: "Սխալ հեռախոսահամար",
    phoneRequired: "Հեռախոսահամարը պարտադիր է",
    addressRequired: "Հասցեն պարտադիր է",
    cityRequired: "Քաղաքը պարտադիր է",
  },
  data: {
    notSupportedFileFormat: "նկարի այս ձևաչափը չի սպասարկվում",
    currency: "դր",
  },
  featuredText: {
    title: "Ընտրիր, Պատվիրիր և Ստացիր",
    description:
      "Նկարներ ընտրելը և տպելը այսուհետ շատ ավելի պարզ է քան կարելի էր պատկերացնել: Միայն մեկ գործողությամբ վերբեռնիր լուսանկարներդ համակարգչից կամ հեռախոսից, գրանցիր պատվեր և ստացիր լավագույն հիշողություններդ՝ տպված նկարների տեսքով, քո իսկ ընտրած վայրում: Ահա այսքան հեշտ է թվային նկարները վերածելու հրաշալի զգացողություն արթնացնող լուսանկարների:",
  },
  faq: {
    title: "Հաճախ տրվող հարցեր",
    fileFormat: {
      question: "Ի՞նչ ֆոտո ֆայլի ձևաչափերներ եք ընդունում:",
      answer:
        "Մենք ընդունում ենք JPEG, PNG և այլ ֆոտո ֆայլի ձևաչափեր նկարները տպելու համար:Լավագույն որակ ստանալու համար անհրաժեշտ է նախօրոք ստուգել նկարների բարձր որակը։",
    },
    deliveryTime: {
      question: "Ի՞նչ ժամանակում է առաքվում պատվերները",
      answer:
        "Առաքման ժամկետը կախված առաքման վայրից և պատվերի քանակից կարող է տարբերվել, Երևանում առաքումները կատարվում են 1-3, իսկ մարզերում 2-7 աշխ. օրերի ընթացում:",
    },
    orderModification: {
      question: "Արդյո՞ք կարող եմ փոփոխել կամ չեղարկել պատվերը:",
      answer:
        "Մինչև պատվերի հաստատումը կարող եք կատարել փոփոխություններ ինչպես նաև չեղարկել պատվերը, հաստատումից հետո արդեն փոփոխություն կամ չեղարկում ցավոք հնարավոր չէ:",
    },
    shippingCosts: {
      question: "Ի՞նչպես է հաշվարկվում առաքման արժեքը:",
      answer:
        "Առաքման արժեքը անկախ ձեր առաքման վայրից հաշվարկվում է նույն տրամաբանությամբ՝ 1500 դրամը գերազանցող պատվերի դեպքում արժեքն է 600 դրամ, այլ պարագայում առաքումը կարժենա 1200 դրամ:",
    },
    damagedPhotos: {
      question: "Ի՞նչ պետք է անել վնասված պատվեր ստանալու դեպքում:",
      answer:
        "Մենք անում ենք ամեն բան պատվերը անվնաս առաքելու համար, ամեն դեպքում խնդիր նկատելու դեպքում անհրաժեշտ է նկարել այն և դիմել մեզ խնդիրը վերացնելու կամ գումարի վերադարձ ստանալու համար:",
    },
  },
};
