import React, { useState, useRef, useEffect, useCallback } from "react";
import { Dropdown, DropdownButton, Card, Container } from "react-bootstrap";
import {
  updateCart,
  updateSelectedSize,
  updateImages,
  updateImagesCount,
  // updateItemsSum,
  // updateSubTotal,
  clearImages,
} from "./../../redux/actions";
import "./Order.css";
import Grid from "./Grid";
import AddToCartModal from "./AddToCartModal";
import { useSelector, useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import Button from "../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/language";
import { CLASSIC_PRINTS_DATA } from "../../data/classicPrintsData";

function Order(props) {
  const { t, i18n } = useTranslation();
  const isArmenian = i18n.language === LANGUAGES.hy;
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const images = useSelector((state) => state.images);
  const cart = useSelector((state) => state.cart);
  const selectedSize = useSelector((state) => state.selectedSize);
  const [touched, setTouched] = useState(false);
  const imagesCount = useSelector((state) => state.imagesCount);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    if (props.match.params.id && !touched) {
      const currentSize =
        CLASSIC_PRINTS_DATA.find(
          (d) => d.id === Number(props.match.params.id)
        ) || CLASSIC_PRINTS_DATA[3];
      dispatch(updateSelectedSize(currentSize));
    }
  }, [dispatch, props.match.params.id, selectedSize, touched]);

  const handleFileInput = (e) => {
    let uploads = [];
    let newImages = [...images];
    uploads.unshift(e.target.files);
    uploads = Object.values(uploads[0]);

    uploads.map((el) => {
      if (
        el.name.includes(".jpg") ||
        el.name.includes(".JPG") ||
        el.name.includes(".JPEG") ||
        el.name.includes(".jpeg") ||
        // el.name.includes(".heic") ||
        // el.name.includes(".HEIC") ||
        el.name.includes(".PNG") ||
        el.name.includes(".png")
        // || el.name.includes(".heic")
      ) {
        newImages.unshift({
          imgSrc: URL.createObjectURL(el),
          img: el,
          id: uuidv4(),
          count: 1,
        });
      } else {
        toast.warn(`"${el.name}" ${t("data.notSupportedFileFormat")}`);
      }
      return newImages;
    });
    dispatch(updateImages(newImages));
  };

  const handleIncreaseCount = (el) => {
    let newImages = [...images];
    const index = newImages.indexOf(el);
    newImages[index] = { ...el };
    newImages[index].count++;
    dispatch(updateImages(newImages));
  };

  const handleDecreaseCount = (el) => {
    if (el.count > 1) {
      let newImages = [...images];
      const index = newImages.indexOf(el);
      newImages[index] = { ...el };
      newImages[index].count--;
      dispatch(updateImages(newImages));
    }
  };

  const handleDelete = (id) => {
    const filteredImages = images.filter((img) => {
      return img.id !== id;
    });

    dispatch(updateImages(filteredImages));
  };

  const handleInputClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleModalButton = () => {
    handleAddToCart();
    setModalShow(true);
  };

  const handleChangeSize = useCallback(
    (el) => {
      // const newSize = [el.size, el.price, "Glossy"];
      dispatch(updateSelectedSize(el));
      setTouched(true);
    },
    [dispatch]
  );

  const handleAddToCart = () => {
    let quantity = imagesCount;
    let price = Number(selectedSize.price) * quantity;
    let newCart = [...cart];
    let newImages = [...images];
    let cartNumber = uuidv4();
    newCart.unshift({
      images: newImages,
      price,
      quantity,
      size: selectedSize.size,
      id: cartNumber,
    });
    dispatch(updateCart(newCart));
  };

  const handleCheckoutClick = () => {
    dispatch(clearImages());
  };
  const handleGoToCart = () => {
    dispatch(clearImages());
  };

  useEffect(() => {
    const checkImagesQuantity = () => {
      let sum = 0;
      for (let key in images) {
        sum += images[key].count;
      }
      dispatch(updateImagesCount(sum));
    };
    checkImagesQuantity();
  }, [dispatch, images]);

  const handleDeleteCartItem = (id) => {
    const filteredCart = cart.filter((cartItem) => {
      return cartItem.id !== id;
    });

    dispatch(updateCart(filteredCart));
  };

  if (images.length > 0) {
    return (
      <main id="order-main">
        <header>
          <div className="left-buttons">
            <Button variant="primary" onClick={handleInputClick}>
              + {t("printService.addPhotos")}
            </Button>
          </div>

          <div className="right-buttons">
            <DropdownButton
              alignRight
              title={selectedSize.size}
              id="dropdown-menu-align-right"
            >
              {CLASSIC_PRINTS_DATA.map((el, i) => {
                return (
                  <Dropdown.Item key={i} onClick={() => handleChangeSize(el)}>
                    {el.size}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>

            <AddToCartModal
              deleteCartItem={(id) => handleDeleteCartItem(id)}
              items={cart}
              addToCart={handleAddToCart}
              goToCart={handleGoToCart}
              checkoutClick={handleCheckoutClick}
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </div>
        </header>
        <input
          type="file"
          id="imageFile"
          name="imageFile"
          accept="image/*"
          style={{ display: "none" }}
          ref={hiddenFileInput}
          onChange={handleFileInput}
          multiple
        />
        <Grid
          increaseCount={handleIncreaseCount}
          decreaseCount={handleDecreaseCount}
          onDelete={handleDelete}
          images={images}
        />
        <div className="bottom-footer">
          <div className="main-quantity">
            {imagesCount} {t("printService.bottomText")}
          </div>
          <Button
            width={isArmenian ? "200px" : ""}
            className="cta-button"
            variant="secondary"
            onClick={handleModalButton}
          >
            {t("printService.addToCart")}
          </Button>
        </div>
      </main>
    );
  } else {
    return (
      <main id="main">
        <Container
          style={{
            marginTop: "120px",
            height: "fit-content",
          }}
        >
          <Card className="text-center">
            <Card.Header className="p-3" />
            <Card.Body>
              <Card.Title>{t("printService.uploadTitle")}</Card.Title>
              <Card.Text>{t("printService.uploadText")}</Card.Text>
              <Button variant="secondary" onClick={handleInputClick}>
                {t("printService.cta")}
              </Button>
              <input
                type="file"
                id="imageFile"
                name="imageFile"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileInput}
                ref={hiddenFileInput}
                multiple
              />
            </Card.Body>
          </Card>
        </Container>
      </main>
    );
  }
}

export default Order;
