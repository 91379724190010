import React from "react";
import { Button as BootstrapButton } from "react-bootstrap";
import styled from "styled-components";

const StyledButton = styled(BootstrapButton)`
  background-color: "red !important";
  font-weight: 600;
  color: ${({ variant }) => (variant === "secondary" ? "white" : "")};
  width: ${({ width }) => width || ""};
  width: ${({ fullWidth }) => fullWidth && "100%"};
  height: ${({ height }) => height || "40px"};
  margin: ${({ margin }) => margin};
  border-radius: 12px;
  &:hover {
    color: ${({ variant }) =>
      ["secondary", "outline-secondary"].includes(variant) ? "white" : ""};
  }
  &:active {
    color: ${({ variant }) =>
      ["secondary", "outline-secondary"].includes(variant) ? "white" : ""};
  }
  &:focus &:hover {
    color: ${({ variant }) =>
      ["secondary", "outline-secondary"].includes(variant) ? "white" : ""};
  }
  &:focus {
    color: ${({ variant }) => (["secondary"].includes(variant) ? "white" : "")};
  }
`;

const Button = ({
  variant,
  children,
  width,
  fullWidth,
  height,
  ...restProps
}) => {
  return (
    <StyledButton
      // fullWidth={fullWidth}
      width={fullWidth ? "100%" : width}
      variant={variant}
      height={height}
      {...restProps}
    >
      {children}
    </StyledButton>
  );
};

export default Button;
